<template>
  <v-card
    :flat="$route.name == 'pf_admin_fairs'"
    max-height="calc(100vh - 48px - 32px)"
    class="rounded-lg"
    >
    <v-card-text
      class="black--text"
      >
      <div
        class="text-center"
        >
        <v-icon
          x-large
          color="primary"
          >
          mdi-chart-bar
        </v-icon>
      </div>

      <div
        class="text-h6 font-weight-bold text-center mb-3 primary--text"
        >
        Analíticas
      </div>

      <div>
        <v-row>
          <template
            v-for="entry in Object.entries(fair.analytics)"
            >
            <v-col
              :md="entry[0] == 'commission' ? 12 : 4"
              cols="12"
              >
              <v-card
                outlined
                class="rounded-lg"
                >
                <v-card-title
                  :style="entry[0] == 'commission' ? '' : 'height: 96px'"
                  class="justify-center text-center"
                  >
                  {{ $t('fairs.' + entry[0]) }}
                </v-card-title>

                <v-card-subtitle
                  v-if="entry[0] == 'commission'"
                  >
                  * la comisión de las ventas realizadas en la feria será acreditada posterior a la finalización de la misma.
                </v-card-subtitle>

                <v-card-text>
                  <div
                    class="font-weight-medium text-h5 primary--text text-center"
                    >
                    {{ entry[1] }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  data: () => ({
  }),

  props: {
    fair: {
      required: true,
      type: Object
    }
  }
}
</script>
